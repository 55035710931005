.form-wrapper {
	background: #004d58;
	padding: 80px 24px 160px 24px;
}

.form .card {
	background: #fbfdfc;
	border-radius: 30px;
	padding: 48px;
	max-width: 1000px;
	margin: auto;
	display: grid;
	grid-template-columns: 40fr 60fr;
	gap: 0 40px;
}

.width-300 {
	max-width: 300px;
}

.form .text ol {
	list-style-type: decimal;
	list-style-position: inside;
}

.form .details {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
	align-content: start;
}

.colspan-2 {
	grid-column: 1 / 3;
}

.form .details input {
	background-color: #fff;
	border-radius: 100px;
	outline: #004d58;
	font-size: 16px;
}

.form .section-label {
	margin: 0 14px 10px 14px;
}

.form .locations {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
	background-color: #004d5811;
	border-radius: 20px;
	padding: 10px;
}

.form .radios {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	background: #004d5811;
	padding: 10px 12px;
	border-radius: 20px;
}

.form .radios label {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
}

.form .radios input[type='radio'] {
	/* Hide default radio button */
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 20px;
	height: 20px;
	border: 2px solid #004d58;
}

.form .radios input[type='radio']:checked {
	background-color: #004d58;
}

.shift-text {
	position: relative;
	top: 2px;
}

.city_button {
	width: 140px;
	height: 120px;
	border-radius: 10px;
	background-color: #ffffff;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border: 5px solid #fbfdfc;
	box-sizing: content-box;
	position: relative;
}

.city_button div {
	position: absolute;
	inset: auto 0 0 0;
	text-align: center;
	background: rgba(255, 255, 255, 0.8);
	font-family: 'agrandir', Arial;
	font-size: 16px;
	font-weight: 600;
	color: #004d58;
	padding: 8px 0;
	border-radius: 0 0 4px 4px;
}

.effect {
	filter: grayscale(100%);
	transition: ease 0.1s;
}

.effect:hover {
	filter: grayscale(0%) !important;
}

.no_effect {
	filter: grayscale(100%) !important;
}

.city_button_selected {
	border: 5px #004d58 solid !important;
	filter: grayscale(0%) !important;
}

.appointments {
	grid-column: 1 / 3;
}

.options {
	background: #004d5811;
	padding: 14px;
	border-radius: 20px;
}

.options-grid {
	display: grid;
	gap: 20px;
}

.options .date {
	margin: 0;
}

.errors {
	display: grid;
	gap: 8px;
}

.errors .error {
	border-radius: 20px;
}

.contact-photo{
	width: 150px;
	height: 150px;
	margin: 30px auto;
	border-radius: 100px;
}

@media screen and (max-width: 768px) {
	.form .card {
		grid-template-columns: 1fr;
		padding: 24px;
	}
	.form .locations {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.city_button {
		width: auto;
		height: 80px;
	}

	.city_button div {
		padding: 4px 0;
		font-size: 0.8em;
		background: rgba(255, 255, 255, 0.9);
	}

	.form .details {
		grid-template-columns: 1fr;
	}

	.colspan-2,
	.appointments {
		grid-column: 1/2;
	}
}
